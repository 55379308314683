@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";

html,body{
  margin: 0;
  background-color: #d9d9d9;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  min-height: 100vh;
}
a,
a:link,
a:hover,
a:visited{
  border: none !important;
  text-decoration: none !important;
}

#root{
  min-height: 100vh;
  position: relative;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.cursor-pointer,
.cursor-pointer svg{
  cursor: pointer;
}

.cursor-pointer:hover{
  color: #ff0000;
}

.cursor-pointer-dark{
  cursor: pointer;
}
.cursor-pointer-dark:hover{
  color: #333;
}

a,
a.link,
a.hover{
  color: #ff0000;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.disconnected{
  position: absolute;
  right: 20px;
  bottom: 20px;
  padding: 10px;
  background-color: #fff;
  border: 2px solid #ccc;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.feature{
  margin: 8px;
  padding: 10px;
  background-color: #f2f2f2;
  border:solid 1px #d1d1d1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.feature_gray{
  margin: 0 8px;
  margin-bottom: 2px;
  padding: 10px;
  background-color: #f2f2f2;
  border:solid 1px #d1d1d1;
  -webkit-border-radius: 0 0  4px  4px  ;
  -moz-border-radius: 0 0  4px  4px ;
  border-radius: 0 0  4px  4px ;
}

.feature_invert{
  color: #fff;
  margin: 0 8px ;
  padding: 10px;
  background-color: #222;
  border:solid 1px #111;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.text-start{
  text-align: left;
}
.text-primary{
  font-size: 16px;
}

.mt-2{
  margin-top: 20px !important;
}

.p-1{
  padding: 10px !important;
}
.p-2{
  padding: 20px !important;
}
.m-0{
  margin: 0 !important;
}
.mb-05{
  margin-bottom: 5px !important;
}
.mb-1{
  margin-bottom: 10px !important;
}
.mb-2{
  margin-bottom: 20px !important;
}
.rbc-event{
  font-size: 10px;
}
.rbc-event .rbc-event-label{
  font-size: 16px;
  font-weight: bold;
}

.title-secondary{
  /*font-size: 14px !important;*/
  font-weight: bold !important;
  color: #ff0000;
}

.title-xs{
  /*font-size: 12px !important;*/
  /*font-weight: bold !important;*/
  color: #333;
}

table td,
table th{
  /*font-size: 14px !important;*/
}

.text-gray{
  color: #555;
}

.text-justify{
    text-align: justify;
}

.text-right{
  text-align: right;
}

.text-center{
  text-align: center;
}

.chat-textarea{
  margin: 0px;
}

.chat-content{
  max-height: 400px;
  overflow: hidden;
  overflow-y:scroll;
  scrollbar-width: thin;
}

.chat-items{
  padding: 5px;
  background-color: #fff;
  /*margin: 10px !important;*/
  border-bottom: #ff0000 solid 3px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 10px !important;
  margin-right: 100px !important;
}

.chat-items .autor{
  font-size: 11px !important;
  font-weight: bold !important;
  color: #ff0000;
  text-transform: capitalize !important;
}
.chat-items .date{
  padding-right: 10px;
  text-align: right;
  font-size: 10px !important;
  color: #999;
  text-transform: capitalize !important;
}

.chat-items .text{
  padding: 5px 10px;
  font-size: 13px !important;
  color: #999;
  text-transform: capitalize !important;
}

.bg-white{
  background-color: #fff;
}
hr{
    border: 1px solid #ff0000;
    border-radius: 5px;
  }
.cam-content{
  position: relative;
}
.cam-content input{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  filter: alpha(opacity=0);
 -moz-opacity: 0;
 -khtml-opacity: 0;
  opacity: 0;
}

.img-fluid{
  width: 100%;
}

.title{
  background-color: #333;
}

.max-height-400{
  max-height: 400px;
  overflow: hidden;
  overflow-y: scroll;
}

.simula_input{
  border: 1px solid #bbb;
  border-radius: 4px;
  padding: 16.5px 14px;
  margin: 8px;
}

.ml-1{
  margin-left: 7.5px !important;
}
.mr-1{
  margin-right: 7.5px !important;
}

.ml-2{
  margin-left: 15px !important;
}
.mr-2{
  margin-right: 15px !important;
}

.pr-1{
  padding-right: 7.5px !important;
}
.pl-1{
  padding-left: 7.5px !important;
}

.pr-2{
  padding-right: 15px !important;
}
.pl-2{
  padding-left: 15px !important;
}

.item-gray{
    background-color: #f2f2f2;
}

.logo{
  max-width: 120px;
}

.logo-xs{
  max-width: 60px;
}

a.btn{
  color: #333;
  background: #fff;
  margin: 2px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

a.btn:hover{
  background: #ddd;
}

.card .MuiGrid-item{
  padding-left: 5px !important;
  padding-top: 5px !important;
}

.border-separated{
  margin-top: 10px;
  margin-bottom: 12px;
  border-bottom: 1px solid #eee;
}

.bg-primary{
  background-color: #ff0000;
  color: #fff;
}

.bg-white{
  background-color: #fff;
}

.toolbarClassName{
  background-color: #333;
}

.height-400{
  height: 400px;
}

.editor{
  margin: 10px;
  padding: 10px;
  border: solid 1px #ddd;
}

.editorClassName{
  background-color: #f2f2f2;
}

.MuiCardHeader-title{
  font-weight: bold !important;
}
.row{
  display: flex;
  flex-direction: row;
}
.col{
  flex: 1 1 auto;
  padding: 10px;
}

.text-complete img{

}

footer{
  min-height: 300px;
  background-color: #010101;
  padding: 30px;
  color:white;
}

p{
  text-align: justify;
}

.btn2 {
  width: 100%;
  background: #FF4742;
  border: 1px solid #FF4742;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.btn2:hover,
.btn2:active {
  background-color: initial;
  background-position: 0 0;
  color: #FF4742;
}

.btn2:active {
  opacity: .5;
}

.min-height-80{
  min-height: '85vh';
  margin-bottom:100px;
}

.min-width-10{
  min-width: 10%;
}

.min-width-20{
  min-width: 20%;
}

.min-width-30{
  min-width: 30%;
}

.min-width-40{
  min-width: 40%;
}

.min-width-50{
  min-width: 50%;
}

.col-3{
  width: 33.3%;
  padding: 1%;
}

.col-4{
  width: 25%;
  padding: 1%;
}

.col-6{
  width: 50%;
  padding: 1%;
}

.card{
  background-color: #fff;
  -webkit-box-shadow: 0px 5px 16px -6px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 5px 16px -6px rgba(0,0,0,0.75);
  box-shadow: 0px 5px 16px -6px rgba(0,0,0,0.75);

}

.border-bottom-red{
  border-bottom: 4px solid red;
}

.card h3{
  text-align: center;
  padding: 10px;
  margin: 0;
}

.card div.bg-primary{
  text-align: center;
  padding: 20px;
  margin: 0;
}

.d-block{
  display: block;
}

.border-bottom-primary{
  border-bottom: 3px solid #ff0000;
}

.fecha_actual input[type="date"]{
   color: white !important;
}

.fecha_actual.active input[type="date"]{
   color: #333 !important;
}

input[type="date"]:focus{
    color: #333 !important;
}

div.text-complete .card div{
  padding: 10px;
}
.h1{
  font-size: 22pt;
}

@media(max-width:770px){
  .MuiBreadcrumbs-li,
  .css-15vhvyh-MuiTypography-root{
    font-size: 12px !important;
  }

  .row{
    display: flex;
    flex-wrap: wrap;
  }

  .row .col{
    text-align: justify;
  }

  .row .col-4{
    width: 100%;
  }

  .row .col-6{
    width: 100% !important;
  }

  .h7{
    font-size: 12px !important;
  }

  .h7 svg{
    width: 0.5em;
    height: 0.5em;
    margin-right: 3px;
  }

  .MuiStepper-horizontal{
    max-width: 100%;
    overflow: hidden;
    overflow-x: scroll;
  }

  .h1{
    font-size: 16pt;
  }

}
